@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

/* === SET UP & UTILITY === */

:root {
  --primary: #F7F6F2;
  --secondary: #E1D7C6;
  --background: #C8C6C6;
  --accent: #C66771;
  --accent2: #4E6C50;
  --black: #000;
  --title: 'Questrial', sans-serif;
  --text-font: 'Montserrat',sans-serif;
}

* {
  box-sizing: border-box;
}

html{
  min-height: 100%;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--text-font);
  background-color: var(--background);
}

.app{
  min-height: 100vh;
  min-height: 100dvh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: var(--primary);
}

a:hover {
  cursor: pointer;
}

section{
  margin-top: 3rem;
}

.section-title{
  color: var(--black);
  font-size: 1.7rem;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

h1, h2, h3, h4, h5, h6{
  font-family: var(--title);
}


/* === HEADER === */

header{
  width: 100%;
  background-color: var(--accent2);
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  color: var(--secondary);
}

header h2 {
  font-weight: bold;
  font-size: 1.7rem;
  margin: 0;
}

.navbar-container{
  width: 70%;
}

.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.navbar ul {
  list-style: none;
}

.navbar li {
  cursor: pointer;
}

.navbar ul li a {
  font-size: 1.2rem;
  color: var(--secondary);
}

header nav ul li a:hover {
  color: var(--primary);
}

/* === Project Card Styles === */

.grid-container{
  display: grid;
  margin: 3rem auto;
  max-width: 70%;
}

.card {
  background-color: var(--accent2);
  color: var(--secondary);
  border-radius: 0.2rem;
  flex: 1 1 21rem;
  display: flex;
  flex-direction: column;
  align-self: normal;
}

.columns{
  display: flex;
  gap: 1rem;
}

.card__img {
  border-radius: 0.2rem 0.2rem 0 0 ;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  object-position: left;
}

.card-content{
  margin: 0 1rem;
}

.card-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 1rem 1rem 0;
}

.card-info{
  font-size: 1rem;
  margin: 1rem 1rem 1.5rem;
}

.card-links {
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
}

.card-links button {
  background-color: var(--background);
  padding: 8px 25px;
  font-size: 1rem;
  border-radius: 0.3rem;
  border: none;
  margin: auto 0 0.5rem;
}

.card-links button a {
  color: #333;
}

.card-links button:hover {
  opacity: 0.9;
  cursor: pointer;
}

/* ABOUT SECTION */

.profile-card{
  max-width: 70%;
  margin: 0 auto;
  background-color: var(--accent2);
}

.profile-info{
  display: flex;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  color: var(--primary);
}

.img-container{
  max-width: 23em;
  max-height: auto;
  margin: 1rem;
}

.img-container img{
 width: 100%;
 object-fit: cover;
}

.bio{
  line-height: 1.25;
  font-family: var(--text-font);
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1.5rem;
}

.bio h2{
  margin: 1rem 0 0 0;
  color: var(--primary)
}

.bio p{
  max-width: 90%;
  flex: 1;
  font-size: 1.1rem;
  text-align: left;
}

.social-icons-container{
  margin-bottom: 0.5rem;
}

.social-icons-col{
  display: flex;
}

.social-icons-col .social-description {
  margin-left: 1rem;
  display: none;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.social-icons-col:hover .social-description {
  display: block;
  animation: slide-in 0.5s forwards;
}

.bio-social-icons {
  color: var(--accent);
}

.bio-social-icons:hover{
  color: var(--background);
}

/* === FOOTER === */

footer{
  background-color: var(--accent2);
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 6rem;
  margin-top: 3rem;
}

footer p {
  color: var(--secondary);
}

.footer-social-container{
  margin-right: 1rem;
}

.footer-icons{
  color: var(--primary);
  margin: 0.3rem;
}

.footer-icons:hover{
  color: var(--accent);
  cursor: pointer;
}

/* Media Queries */

@media screen and (max-width: 1316px) {
  .social-icons-col{
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .profile-info{
    flex-direction: column;
    align-items: center;
  }

  .img-container img{
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }

  .columns{
    flex-direction: column;
    gap: 2rem;
  }

}

@media screen and (max-width: 500px){
  .grid-container, .profile-card{
    max-width: 90%;
  }

  header h2, .navbar ul li a {
    font-size: 4.5vw;
  }
}